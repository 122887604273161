.App {
  text-align: center;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  min-height: 100vh;
}

.App-logo {
  position: absolute;
  right: 10px;
  top: 25px;
  
  height: 70px;
  pointer-events: none;
}

.Wait-logo {
  position: fixed;
  top: 45vh;
  width: 100px;
  pointer-events: none;
}

.Wait-text {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Wait-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

.CallMailbox{
  cursor: pointer;
  color: red;
  font-size: 30px;
  min-height: 60px;
  width: 100%;
}

.Navbar{
  background-color: #1d2025;
  display: flex;
  text-align: left;
  font-size: calc(2px + 2vmin);
  color: white;
  width: 100%;
}

.SubNavbar{
  background-color: white;
  display: flex;
  text-align: left;
  font-size: calc(2px + 2vmin);
  color: #1d2025;
  width: 100%;
  margin-top: -25px;
}

li {
  display: inline;
  line-height: 80px;
  margin-right: 40px;
  cursor: pointer;
}


th{
  padding-right:20px;
  padding-bottom: 30px;
  font-size: 20px;
  text-align: left;
}


table{
  margin: 10px;
  margin-top: 50px;
  margin-bottom: 100px;
  max-width: 95%;
  min-width: 50%;
}

hr{
  width: 100%;
}

input[type=checkbox]{
  transform: scale(2);
}

button{
  height: 30px;
  margin-top: 15px;
}

.login{
  margin-top: 40vh;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Nav-Links{
  display: flex;
  text-align: center;
  width: 90%;
  margin-bottom: 10px;
  margin-top: 5px;
}

.column {
  display: inline-block;
  flex: 15%;
  min-width: 75px;
  min-height: 75px;
  background: #ccc;
  margin: 10px;
  object-fit: cover;
}

.Nav-Img{
  width: 70%;
}

@media screen and (min-width: 900px) {
  br {
    display: none;
  }
}

.newContact{
  text-align: right;
  margin: 50px;
  cursor: pointer;
}

.absolutePanel{
  position: absolute;
  height: 100vh;
  width: 100%;
  background-color: black;
}

th, td {
  padding: 20px;
  text-align: left;
  border-bottom: 1px solid;
}

table {
  border: 1px solid;
}

.form{
  width: 100%;
}
.formRow{
  width: 100%;
}

.saveButton{
  text-align: right;
  margin-top: 20px;
  margin-bottom: 20px;
}

.space{
  margin-right: 100px;
  width: 250px;
}
.smallSpace{
  margin-right: 15px;
}

.clickabale{
  cursor: pointer;
}

.clickabale:hover{
  cursor: pointer;
  background-color: lightgrey;
  color: black;
}

.upperSpace{
  margin-top: 30px;
}

.upperSpaceLable{
  margin-top: 33px;
}

.column{
  background-color: #282c34;
  text-align: left;
  align-items: left;
  align-content: left;
  vertical-align: top;
}

.Loading{
  color: red;
}

.SearchBar{
  width: 80%;
  height: 40px;
  border-radius: 15px;
  padding-left: 10px;
  padding-right: 10px;
}

.searchWindow{
  width: 75%;
  background-color: white; 
  padding: 0px;
}

.searchTable{
  border: none;
  width: 100%;
  padding: 0px;
}

.searchResult{
  color: black;
  font-size: small;
  border-spacing: 0 0px;
  padding: 0px;
}